#app {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

canvas {
    background-color: white;
		border: 1px solid slategray;
    border-radius: 5px;
    padding: 1rem;
    height: 100%;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    overflow-x: scroll;
    overflow-y: scroll;
    padding: 0.75rem;
}

fieldset {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
}

fieldset div {
	display: inline-flex;

	justify-content: space-between;
}

fieldset div * {
	width: 100%;
}
