#app {
  min-height: 100vh;
  height: 100%;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

canvas {
  height: 100%;
  background-color: #fff;
  border: 1px solid #708090;
  border-radius: 5px;
  padding: 1rem;
}

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: .75rem;
  overflow: scroll;
}

fieldset {
  flex-direction: column;
  gap: .75rem;
  display: flex;
}

fieldset div {
  justify-content: space-between;
  display: inline-flex;
}

fieldset div * {
  width: 100%;
}

/*# sourceMappingURL=index.4b694c76.css.map */
